import { Layout } from '../../components/Layout';
import { MessageDiv } from '../../styles/formularz';
import React from 'react';
import { StyledArticle } from '../../styles';
import { StyledH2 } from '../../styles/common';
import { SEO } from '../../components/SEO';

export const Head = () => (
	<SEO 
		title='Przewozy'
		description='Usługi przewozowe.'
	/>);

const PrzewozyPage = () => (
	<Layout>
		<StyledArticle>
			<StyledH2 as="h1" color="info">
				Przewozy
			</StyledH2>
			<MessageDiv>
				Świadczenie usług transportowych (w tym okolicznościowe) tramwajami,
				autobusami przegubowymi i standardowymi na terenie miasta Bydgoszczy
				oraz pozostałe usługi.
			</MessageDiv>
		</StyledArticle>
	</Layout>
);

export default PrzewozyPage;
